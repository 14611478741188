import React, {  useRef,useEffect, useState } from 'react';

function Playlist(props){


  //let currentSourceIndex=0;
  const savedCallback = useRef();
  const [currentSourceIndex,setCurrentSourceIndex] = useState(0);

  useEffect(() => {
    savedCallback.current=playNext;
  },[currentSourceIndex,props.sources])

  const selectSource = (i,forceMaximized=false) => {
    console.log("playing " + (i+1) + " from " + props.sources.length)
    //this.props.changeSource(this.state.sources[i]); 
    setCurrentSourceIndex(i);
    //console.log(this.props.sources[i]);
    //props.closePlayer();
    props.showPlayer(props.sources[i],() => () => savedCallback.current(),forceMaximized);
  };

  // const addSource = (src) => {
  //   var newSources = sources;
  //   newSources.push(src);
  //   setSources(newSources);
  // };

  const playNext = () => {
    console.log("next");
    var nextSourceIndex = currentSourceIndex+1;
    console.log(nextSourceIndex);
    console.log("props.sources.length: " + props.sources.length);
    if(nextSourceIndex<props.sources.length){
      selectSource(nextSourceIndex);
    }
  };

  const removeSource = (i) => {
    if(i<=currentSourceIndex){
      setCurrentSourceIndex(currentSourceIndex-1);
    }
    props.removeSource(i);
  }



      return(
        <div class="container">
          <button class="close-btn" onClick={props.closePlaylist}>×</button>
          {
            props.sources.map(
              (source,i) => {
                return <div key={"playlistElement" + i}
                            className={currentSourceIndex == i ? "row playlistActive" : "row"}>
                  <div className="col">{source.title}</div>
                  <div className="col"><span onClick={() => selectSource(i, true)}>▶</span></div>
                  <div className="col"><span style={{color:"#D32F2F"}} onClick={() => removeSource(i)}>x</span></div>
                </div>
              }
            )
          }
        </div>
      );


}

export default Playlist;